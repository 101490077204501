import { Controller } from "@hotwired/stimulus"
import { HtmlRenderer, Parser } from "commonmark"

// General-purpose markdown editing controller with preview
export default class extends Controller {
    static targets = ["container", "editor", "body", "preview"]

    declare readonly bodyTarget: HTMLTextAreaElement
    declare readonly editorTarget: HTMLElement
    declare readonly containerTarget: HTMLElement
    declare readonly previewTarget: HTMLButtonElement

    // Track the preview element here
    previewElem: HTMLElement | null = null

    parser: Parser
    renderer: HtmlRenderer
    
    connect() {
        this.parser = new Parser();
        this.renderer = new HtmlRenderer();
    }

    preview() {
        if (this.previewElem) {
            // Swap back to the editor if we're previewing already
            this.containerTarget.removeChild(this.previewElem)
            this.previewElem = null
            this.editorTarget.style.removeProperty("display")
            this.previewTarget.textContent = "Preview"
        } else {
            let str = this.bodyTarget.value

            // Render the body contents to HTML
            let result = this.renderer.render(this.parser.parse(str));

            // Create a new preview element with the rendered HTML as its contents
            let preview = document.createElement("div")
            preview.innerHTML = result
            preview.className = "markdown-preview"
            preview.style.height = `${this.editorTarget.clientHeight}px`
            preview.style.width = `${this.editorTarget.clientWidth}px`

            // Replace the editor with our new preview element
            this.editorTarget.style.display = "none"
            this.containerTarget.appendChild(preview)
            this.previewElem = preview

            this.previewTarget.textContent = "Edit"
        }
    }
}
