import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["body", "submit", "cancel"]

    declare readonly bodyTarget: HTMLTextAreaElement
    declare readonly submitTarget: HTMLInputElement
    declare readonly cancelTarget: HTMLButtonElement
    declare readonly hasCancelTarget: boolean

    connect(): void {
        this.edited()
    }

    isEmpty(): boolean {
        return this.bodyTarget.value.trim() == ""
    }

    edited() {
        let empty = this.isEmpty()
        this.submitTarget.disabled = empty
        if (this.hasCancelTarget) {
            if (empty) { this.cancelTarget.classList.remove("negative") }
            else { this.cancelTarget.classList.add("negative") }
        }
    }

    keyDown(ev: KeyboardEvent) {
        if (ev.key == "Enter" && ev.ctrlKey && this.isEmpty()) {
            this.submitTarget.click()
            ev.preventDefault()
        }
    }

    clear() {
        // HACK: Don't clear before the form submits
        setTimeout(() => this.bodyTarget.value = "", 1)
    }

    cancel() {
        this.element.parentNode?.removeChild(this.element)
    }
}
