import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["children", "replyButton"];
    static values = { id: String, replyPath: String }

    declare readonly idValue: string
    declare readonly replyPathValue: string
    declare readonly childrenTarget: HTMLElement
    declare readonly replyButtonTarget: HTMLElement | null

    template: HTMLTemplateElement
    form: HTMLElement

    connect(): void {
        let form = document.getElementById("comment-form-template")
        if (form && form instanceof HTMLTemplateElement) {
            this.template = form
        } else {
            console.error("Comment controller couldn't find expected form element", form)
        }
    }

    reply() {
        // If we already have a comment form open, close it
        if (this.childrenTarget.contains(this.form)) {
            this.childrenTarget.removeChild(this.form)
            this.replyButtonTarget?.classList.remove("active")
            return
        }

        let frag = this.template.content.cloneNode(true) as DocumentFragment
        let form = frag.getElementById("placeholder") as HTMLFormElement
        // The URL already contains the post ID. Just add `/<comment id>` to
        // the end to reply to a parent comment.
        form.action = this.replyPathValue
        form.id = `comment-${this.idValue}-form`
        this.form = form
        this.childrenTarget.prepend(frag)
        this.replyButtonTarget?.classList.add("active")
    }
}
