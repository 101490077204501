import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "thumb", "embed", "read" ]
    static values = {
        embed: String,
        title: String,
    }

    declare readonly hasReadTarget: boolean
    declare readonly readTarget: HTMLElement

    declare readonly thumbTarget: HTMLElement
    declare readonly embedTarget: HTMLElement
    declare readonly embedValue: string
    declare readonly titleValue: string

    elem: HTMLElement | null

    connect(): void {
        this.elem = null
    }

    toggle(ev: PointerEvent) {
        if (this.embedTarget.hasChildNodes()) {
            this.closeEmbed()
            this.sendRead()
            ev.preventDefault()
        } else if (this.openEmbed()) {
            ev.preventDefault()
        }
    }

    /// Expands the embed for this post if one exists
    ///
    /// Returns true if this post had an embed. False otherwise
    openEmbed(): boolean {
        let elem = this.embedElem()
        if (!elem) { return false }

        this.embedTarget.appendChild(elem)
        this.thumbTarget.classList.add("active")
        return true
    }

    closeEmbed() {
        let embed = this.embedTarget.children.item(0)
        if (embed) { this.embedTarget.removeChild(embed) }
        this.thumbTarget.classList.remove("active")
    }

    // Check if this post can embed its URL and if so, return the HTML element
    // to insert.
    embedElem(): HTMLElement | null {
        if (this.elem) { return this.elem }

        if (this.embedValue != "") {
            let embedElem = document.createElement("img")
            embedElem.alt = this.titleValue
            embedElem.src = this.embedValue
            return embedElem
        } else {
            return null
        }
    }

    // Actually request the backend mark as read.
    sendRead() {
        if (!this.hasReadTarget) { return }
        if (!this.readTarget.classList.contains("active")) {
            this.readTarget.click()
        }
    }

    // Mark read visually on frontend only
    read() {
        this.element.classList.add("read")
        this.readTarget.classList.add("active")
        this.readTarget.ariaChecked = "true"
    }
}
