import { Controller } from "@hotwired/stimulus"

// Simple controller for dropdowns which just updates the `aria-expanded` attribute
export default class extends Controller {
    static targets = ["check", "menu"]

    declare readonly checkTarget: HTMLInputElement
    declare readonly menuTarget: HTMLElement

    toggle() {
        this.menuTarget.ariaExpanded = String(this.checkTarget.checked)
    }
}
